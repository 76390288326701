export const textStrings = {
    "": {
        EN: "",
        FR: ""
    },
    header_welcome: {
        EN: "Welcome to Storex - the cryptocurrency e-commerce marketplace",
        FR: "Bienvenue sur Storex - le marché du commerce électronique de crypto-monnaie"
    },
    header_help: {
        EN: "Help Center",
        FR: "Centre d'aide"
    },
    header_notifications: {
        EN: "Notifications"
    },
    header_sell: {
        EN: "Create Listing",
        FR: "Créer une liste"
    },
    header_messages: {
        EN: "Messages",
        FR: "Avis"
    },
    header_account: {
        EN: "My Account",
        FR: "Mon compte"
    },
    header_sign_in: {
        EN: "Log In",
        FR: "S'identifier"
    },
    header_sign_out: {
        EN: "Sign Out",
        FR: "Se déconnecter"
    },
    header_all_categories: {
        EN: "All Categories",
        FR: "Toutes Catégories"
    },
    header_search: {
        EN: "Search for an item...",
        FR: "Rechercher un article..."
    },
    header_daily: {
        EN: "Daily",
        FR: "quotidiennes"
    },
    header_weekly: {
        EN: "Weekly"
    },
    header_deals: {
        EN: "Deals",
        FR: "Offres"
    },
    header_wishlist: {
        EN: "Wishlist",
        FR: "Favoris"
    },
    header_cart: {
        EN: "Cart",
        FR: "Panier"
    },
    header_cart_title: {
        EN: "Shopping Cart",
        FR: "Panier"
    },
    header_cart_close: {
        EN: "Close",
        FR: "Panier"
    },
    header_cart_subtotal: {
        EN: "Subtotal",
        FR: "Panier"
    },
    header_cart_view: {
        EN: "View Cart",
        FR: "Panier"
    },
    header_cart_checkout: {
        EN: "Checkout",
        FR: "Panier"
    },
    header_browse: {
        EN: "Browse Categories",
        FR: "Parcourir les Catégories"
    },
    category_antiques_collectables: {
        EN: "Antiques & Collectibles",
        FR: "Antiquités et Objets de Collection"
    },
    sub_autographs: {
        EN: "Autographs",
        FR: ""
    },
    sub_badges_patches: {
        EN: "Badges & Patches",
        FR: ""
    },
    sub_card_games: {
        EN: "Card Games",
        FR: ""
    },
    sub_coins_precious_metals: {
        EN: "Coins & Precious Metals"
    },
    sub_decorative: {
        EN: "Decorative",
        FR: ""
    },
    sub_flags: {
        EN: "Flags",
        FR: ""
    },
    sub_furniture: {
        EN: "Furniture",
        FR: ""
    },
    sub_jewelry: {
        EN: "Jewelry",
        FR: ""
    },
    sub_manuscripts: {
        EN: "Manuscripts",
        FR: ""
    },
    sub_maps_globes: {
        EN: "Maps & Globes",
        FR: ""
    },
    sub_memorabilia: {
        EN: "Memorabilia",
        FR: ""
    },
    sub_metalware: {
        EN: "Metalware",
        FR: ""
    },
    sub_militaria: {
        EN: "Militaria",
        FR: ""
    },
    sub_musical: {
        EN: "Musical",
        FR: ""
    },
    sub_photographs_postcards: {
        EN: "Photographs & Postcards",
        FR: ""
    },
    sub_rocks_minerals: {
        EN: "Rocks & Minerals",
        FR: ""
    },
    sub_tools_hardware: {
        EN: "Tools & Hardware",
        FR: ""
    },
    sub_vintage_retro: {
        EN: "Vintage & Retro",
        FR: ""
    },
    sub_woodenware: {
        EN: "Woodenware",
        FR: ""
    },
    category_books_music: {
        EN: "Books & Music",
        FR: "Livres & Musique"
    },
    sub_audio_accessories: {
        EN: "Audio Accessories",
        FR: ""
    },
    sub_audio_books: {
        EN: "Audio Books",
        FR: ""
    },
    sub_cassettes: {
        EN: "Cassettes",
        FR: ""
    },
    sub_cds: {
        EN: "CD's",
        FR: ""
    },
    sub_comic_books: {
        EN: "Comic Books",
        FR: ""
    },
    sub_fiction_books: {
        EN: "Fiction Books",
        FR: ""
    },
    sub_magazines: {
        EN: "Magazines",
        FR: ""
    },
    sub_non_fiction_books: {
        EN: "Non-fiction Books",
        FR: ""
    },
    sub_textbooks_education: {
        EN: "Textbooks & Education",
        FR: ""
    },
    sub_vinyl_records: {
        EN: "Vinyl & Records",
        FR: ""
    },
    category_business_office: {
        EN: "Business & Office Supplies",
        FR: "Fournitures d'affaires et de Bureau"
    },
    sub_displays_stands: {
        EN: "Displays & Stands",
        FR: ""
    },
    sub_equipment_supplies: {
        EN: "Equipment & Supplies",
        FR: ""
    },
    sub_office_furniture: {
        EN: "Office Furniture",
        FR: ""
    },
    sub_presentation_projectors: {
        EN: "Presentation & Projectors",
        FR: ""
    },
    sub_stationery: {
        EN: "Stationery",
        FR: ""
    },
    category_computers_hardware: {
        EN: "Computers, Network & Hardware",
        FR: "Ordinateurs, Réseau et Matériel"
    },
    sub_computer_components_parts: {
        EN: "Computer Components & Parts",
        FR: ""
    },
    sub_computer_peripherals: {
        EN: "Computer Peripherals",
        FR: ""
    },
    sub_desktop_computers: {
        EN: "Desktop Computers",
        FR: ""
    },
    sub_drives: {
        EN: "Drives",
        FR: ""
    },
    sub_cookware: {
        EN: "Cookware",
        FR: ""
    },
    sub_drinkware: {
        EN: "Drinkware",
        FR: ""
    },
    sub_storage_media: {
        EN: "Storage & Media",
        FR: ""
    },
    sub_home_networking: {
        EN: "Home Networking",
        FR: ""
    },
    sub_laptops_netbooks: {
        EN: "Laptops & Netbooks",
        FR: ""
    },
    sub_monitors_accessories: {
        EN: "Monitors & Accessories",
        FR: ""
    },
    sub_printers: {
        EN: "Printers",
        FR: ""
    },
    sub_scanners_supplies: {
        EN: "Scanners & Supplies",
        FR: ""
    },
    sub_software: {
        EN: "Software",
        FR: ""
    },
    category_electronics: {
        EN: "Electronics",
        FR: "Électronique"
    },
    sub_batteries_power: {
        EN: "Batteries & Power",
        FR: ""
    },
    sub_cameras_photography: {
        EN: "Cameras & Photography",
        FR: ""
    },
    sub_drones: {
        EN: "Drones",
        FR: ""
    },
    sub_dvd_bluray: {
        EN: "DVD & Bluray",
        FR: ""
    },
    sub_headphones: {
        EN: "Headphones",
        FR: ""
    },
    sub_home_appliances: {
        EN: "Home Appliances",
        FR: ""
    },
    sub_home_audio: {
        EN: "Home Audio",
        FR: ""
    },
    sub_home_cinema: {
        EN: "Home Cinema",
        FR: ""
    },
    sub_tablets_ereaders: {
        EN: "Tablets & eReaders",
        FR: ""
    },
    sub_television_accessories: {
        EN: "Television Accessories",
        FR: ""
    },
    sub_televisions: {
        EN: "Televisions",
        FR: ""
    },
    sub_video_game_accessories: {
        EN: "Video Game Accessories",
        FR: ""
    },
    sub_video_game_consoles: {
        EN: "Video Game Consoles",
        FR: ""
    },
    sub_video_games: {
        EN: "Video Games",
        FR: ""
    },
    sub_virtual_reality: {
        EN: "Virtual Reality",
        FR: ""
    },
    category_fashion: {
        EN: "Fashion",
        FR: "Mode"
    },
    sub_jewelry_watches: {
        EN: "Jewelry & Watches",
        FR: ""
    },
    sub_kids_accessories: {
        EN: "Kids' Accessories",
        FR: ""
    },
    sub_kids_clothing: {
        EN: "Kids' Clothing",
        FR: ""
    },
    sub_kids_shoes: {
        EN: "Kids' Shoes",
        FR: ""
    },
    sub_mens_accessories: {
        EN: "Men's Accessories",
        FR: ""
    },
    sub_mens_clothing: {
        EN: "Men's Clothing",
        FR: ""
    },
    sub_mens_shoes: {
        EN: "Men's Shoes",
        FR: ""
    },
    sub_womens_accessories: {
        EN: "Women's Accessories",
        FR: ""
    },
    sub_womens_clothing: {
        EN: "Women's Clothing",
        FR: ""
    },
    sub_womens_shoes: {
        EN: "Women's Shoes",
        FR: ""
    },
    category_health_beauty: {
        EN: "Health & Beauty",
        FR: "Santé & Beauté"
    },
    sub_bath_body: {
        EN: "Bath & Body",
        FR: ""
    },
    sub_electronic_smoking: {
        EN: "Electronic Smoking",
        FR: ""
    },
    sub_fragrances: {
        EN: "Fragrances",
        FR: ""
    },
    sub_hair_styling: {
        EN: "Hair Styling",
        FR: ""
    },
    sub_health_care: {
        EN: "Health Care",
        FR: ""
    },
    sub_makeup: {
        EN: "Makeup",
        FR: ""
    },
    sub_nail_care: {
        EN: "Nail Care",
        FR: ""
    },
    sub_natural_alternative: {
        EN: "Natural & Alternative",
        FR: ""
    },
    sub_oral_care: {
        EN: "Oral Care",
        FR: ""
    },
    sub_shaving_removal: {
        EN: "Shaving & Removal",
        FR: ""
    },
    sub_skin_care: {
        EN: "Skin Care",
        FR: ""
    },
    sub_sun_tanning: {
        EN: "Sun Tanning",
        FR: ""
    },
    sub_tattoos_art: {
        EN: "Tattoos & Art",
        FR: ""
    },
    sub_vision_care: {
        EN: "Vision Care",
        FR: ""
    },
    sub_vitamins_supplements: {
        EN: "Vitamins & Supplements",
        FR: ""
    },
    category_hobbies_leisure: {
        EN: "Hobbies & Leisure",
        FR: "Loisirs"
    },
    sub_art_supplies: {
        EN: "Art Supplies",
        FR: ""
    },
    sub_audio_equipment: {
        EN: "Audio Equipment",
        FR: ""
    },
    sub_beads_jewelry_making: {
        EN: "Beads & Jewelry Making",
        FR: ""
    },
    sub_child_crafts: {
        EN: "Child Crafts",
        FR: ""
    },
    sub_dj_equipment: {
        EN: "DJ Equipment",
        FR: ""
    },
    sub_fabric: {
        EN: "Fabric",
        FR: ""
    },
    sub_framing: {
        EN: "Framing",
        FR: ""
    },
    sub_glass_art: {
        EN: "Glass Art",
        FR: ""
    },
    sub_guitars_basses: {
        EN: "Guitars & Basses",
        FR: ""
    },
    sub_hand_crafted: {
        EN: "Hand Crafted",
        FR: ""
    },
    sub_keyboards_pianos: {
        EN: "Keyboards & Pianos",
        FR: ""
    },
    sub_leathercrafts: {
        EN: "Leathercrafts",
        FR: ""
    },
    sub_mosaic: {
        EN: "Mosaic",
        FR: ""
    },
    sub_music_accessories: {
        EN: "Music Accessories",
        FR: ""
    },
    sub_needlecrafts: {
        EN: "Needlecrafts",
        FR: ""
    },
    sub_percussion_instruments: {
        EN: "Percussion Instruments",
        FR: ""
    },
    sub_scrapbooking_paper: {
        EN: "Scrapbooking & Paper",
        FR: ""
    },
    sub_sculpting_ceramics: {
        EN: "Sculpting & Ceramics",
        FR: ""
    },
    sub_sewing: {
        EN: "Sewing",
        FR: ""
    },
    sub_string_instruments: {
        EN: "String Instruments",
        FR: ""
    },
    sub_wind_woodwind: {
        EN: "Wind & Woodwind",
        FR: ""
    },
    sub_woodworking: {
        EN: "Woodworking",
        FR: ""
    },
    category_home_garden: {
        EN: "Home & Garden",
        FR: "Maison & Jardin"
    },
    sub_garden_patio: {
        EN: "Garden & Patio",
        FR: ""
    },
    sub_garden_tools: {
        EN: "Garden Tools",
        FR: ""
    },
    sub_home_furniture: {
        EN: "Home Furniture",
        FR: ""
    },
    sub_household: {
        EN: "Household Supplies",
        FR: ""
    },
    sub_lawn_mowers: {
        EN: "Lawn Mowers",
        FR: ""
    },
    sub_outdoor_furniture: {
        EN: "Outdoor Furniture",
        FR: ""
    },
    sub_pet_supplies: {
        EN: "Pet Supplies",
        FR: ""
    },
    sub_plant_care: {
        EN: "Plant Care",
        FR: ""
    },
    sub_plants_seeds: {
        EN: "Plants & Seeds",
        FR: ""
    },
    category_jewelry_watches: {
        EN: "Jewelry & Watches",
        FR: "Bijoux & Montres"
    },
    sub_body_jewelry: {
        EN: "Body Jewelry",
        FR: ""
    },
    sub_child_jewelry: {
        EN: "Child Jewelry",
        FR: ""
    },
    sub_child_watches: {
        EN: "Child Watches",
        FR: ""
    },
    sub_costume_jewelry: {
        EN: "Costume Jewelry",
        FR: ""
    },
    sub_fine_jewelry: {
        EN: "Fine Jewelery",
        FR: ""
    },
    sub_mens_jewelry: {
        EN: "Men's Jewelery",
        FR: ""
    },
    sub_mens_watches: {
        EN: "Men's Watches",
        FR: ""
    },
    sub_womens_jewelry: {
        EN: "Women's Jewelery",
        FR: ""
    },
    sub_womens_watches: {
        EN: "Women's Watches",
        FR: ""
    },
    category_phones_smart_watches: {
        EN: "Phones, Smart Watches & Accessories",
        FR: "Téléphones, Montres Intelligentes et Accessoires"
    },
    sub_home_phones_accessories: {
        EN: "Home Phones & Accessories",
        FR: ""
    },
    sub_mobile_phone_accessories: {
        EN: "Mobile Phone Accessories",
        FR: ""
    },
    sub_mobile_phone_parts: {
        EN: "Mobile Phone Parts",
        FR: ""
    },
    sub_mobile_phones: {
        EN: "Mobile Phones",
        FR: ""
    },
    sub_smart_watch_accessories: {
        EN: "Smart Watch Accessories",
        FR: ""
    },
    sub_smart_watches: {
        EN: "Smart Watches",
        FR: ""
    },
    category_services_gigs: {
        EN: "Services & Gigs",
        FR: "Services"
    },
    sub_business: {
        EN: "Business",
        FR: ""
    },
    sub_digital_marketing: {
        EN: "Digital Marketing",
        FR: ""
    },
    sub_graphics_design: {
        EN: "Graphics Design",
        FR: ""
    },
    sub_cards_coupons: {
        EN: "Gift Cards & Coupons",
        FR: ""
    },
    sub_lifestyle: {
        EN: "Lifestyle",
        FR: ""
    },
    sub_music_audio: {
        EN: "Music & Audio",
        FR: ""
    },
    sub_programming_tech: {
        EN: "Programming & Tech",
        FR: ""
    },
    sub_tickets: {
        EN: "Tickets, Events & Travel",
        FR: ""
    },
    sub_video_animation: {
        EN: "Video & Animation",
        FR: ""
    },
    sub_writing_translation: {
        EN: "Writing & Translation",
        FR: ""
    },
    category_sporting: {
        EN: "Sporting Goods",
        FR: "Articles de Sport"
    },
    sub_airsoft: {
        EN: "Airsoft",
        FR: ""
    },
    sub_american_football: {
        EN: "American Football",
        FR: ""
    },
    sub_boxing: {
        EN: "Boxing",
        FR: ""
    },
    sub_camping: {
        EN: "Camping",
        FR: ""
    },
    sub_cricket: {
        EN: "Cricket",
        FR: ""
    },
    sub_cycling: {
        EN: "Cycling",
        FR: ""
    },
    sub_fishing: {
        EN: "Fishing",
        FR: ""
    },
    sub_fitness_tech: {
        EN: "Fitness Tech",
        FR: ""
    },
    sub_fitness_yoga_equipment: {
        EN: "Fitness & Yoga Equipment",
        FR: ""
    },
    sub_football: {
        EN: "Football",
        FR: ""
    },
    sub_golf: {
        EN: "Golf",
        FR: ""
    },
    sub_hunting: {
        EN: "Hunting",
        FR: ""
    },
    sub_martial_arts: {
        EN: "Martial Arts",
        FR: ""
    },
    sub_paintball: {
        EN: "Paintball",
        FR: ""
    },
    sub_rugby: {
        EN: "Rugby",
        FR: ""
    },
    sub_skateboarding: {
        EN: "Skateboarding",
        FR: ""
    },
    sub_strength_training: {
        EN: "Strength Training",
        FR: ""
    },
    sub_swimming: {
        EN: "Swimming",
        FR: ""
    },
    sub_table_tennis: {
        EN: "Table Tennis",
        FR: ""
    },
    sub_tennis: {
        EN: "Tennis",
        FR: ""
    },
    sub_winter_sports: {
        EN: "Winter Sports",
        FR: ""
    },
    category_toys_games: {
        EN: "Toys & Games",
        FR: "Jouets et Jeux"
    },
    sub_action_figures: {
        EN: "Action Figures",
        FR: ""
    },
    sub_construction_toys: {
        EN: "Construction Toys",
        FR: ""
    },
    sub_creative_toys: {
        EN: "Creative Toys",
        FR: ""
    },
    sub_dolls: {
        EN: "Dolls",
        FR: ""
    },
    sub_educational_toys: {
        EN: "Educational Toys",
        FR: ""
    },
    sub_electronic_pets: {
        EN: "Electronic Pets",
        FR: ""
    },
    sub_food_toys: {
        EN: "Food Toys",
        FR: ""
    },
    sub_games: {
        EN: "Games",
        FR: ""
    },
    sub_jigsaws_puzzles: {
        EN: "Jigsaws & Puzzles",
        FR: ""
    },
    sub_models_kits: {
        EN: "Models & Kits",
        FR: ""
    },
    sub_outdoor_toys: {
        EN: "Outdoor Toys",
        FR: ""
    },
    sub_rc_toys: {
        EN: "RC Toys",
        FR: ""
    },
    sub_soft_toys: {
        EN: "Soft Toys",
        FR: ""
    },
    sub_toy_soldiers: {
        EN: "Toy Soldiers",
        FR: ""
    },
    sub_wargames_rp: {
        EN: "Wargames & Roleplay",
        FR: ""
    },
    category_vehicle_parts: {
        EN: "Vehicle Parts & Accessories",
        FR: "Pièces et Accessoires de Véhicules"
    },
    sub_car_accessories: {
        EN: "Car Accessories",
        FR: ""
    },
    sub_car_care: {
        EN: "Car Care",
        FR: ""
    },
    sub_car_parts: {
        EN: "Car Parts",
        FR: ""
    },
    sub_car_tech: {
        EN: "Car Tech",
        FR: ""
    },
    sub_car_tuning: {
        EN: "Car Tuning",
        FR: ""
    },
    sub_car_wheels: {
        EN: "Car Wheels",
        FR: ""
    },
    sub_helmets_protection: {
        EN: "Helmets & Protection",
        FR: ""
    },
    sub_dash_cams: {
        EN: "Dash Cams",
        FR: ""
    },
    sub_garage_equipment: {
        EN: "Garage Equipment",
        FR: ""
    },
    sub_motorcycle_accessories: {
        EN: "Motorcycle Accessories",
        FR: ""
    },
    sub_motorcycle_parts: {
        EN: "Motorcycle Parts",
        FR: ""
    },
    sub_motorcycle_wheels: {
        EN: "Motorcycle Wheels",
        FR: ""
    },
    sub_oils_fluids: {
        EN: "Oils & Fluids",
        FR: ""
    },
    sub_vehicle_services: {
        EN: "Vehicle Services",
        FR: ""
    },
    category_other: {
        EN: "Other",
        FR: "Autre"
    },
    sub_other: {
        EN: "Other",
        FR: "Autre"
    },
    my_account: {
        EN: "My Account"
    },
    account_settings: {
        EN: "Settings"
    },
    check_status: {
        EN: "Check Status"
    },
    begin_verification: {
        EN: "Start Verification"
    },
    export_pk: {
        EN: "Export Private Key"
    },
    verify_email: {
        EN: "Verify Email"
    },
    confirm: {
        EN: "Confirm"
    },
    submit: {
        EN: "Submit"
    },
    account_private_key: {
        EN: "Private Key"
    },
    account_identity_verification: {
        EN: "Identity Verification (KYC)"
    },
    account_sessions: {
        EN: "Sessions"
    },
    account_wallet: {
        EN: "Wallet"
    },
    account_nfts: {
        EN: "NFTs"
    },
    account_addresses: {
        EN: "Addresses"
    },
    account_reg_address: {
        EN: "Registered Address"
    },
    account_bill_address: {
        EN: "Billing Address"
    },
    account_del_address: {
        EN: "Delivery Address"
    },
    account_orders: {
        EN: "Order History"
    },
    account_selling: {
        EN: "Selling"
    },
    account_summary: {
        EN: "Summary"
    },
    account_active_listings: {
        EN: "Active Listings"
    },
    account_selling_history: {
        EN: "Sales"
    },
    account_storefront: {
        EN: "Storefront"
    },
    account_arbitration: {
        EN: "Arbitration"
    },
    account_my_disputes: {
        EN: "My Disputes"
    },
    account_my_cases: {
        EN: "My Cases"
    },
    account_governance: {
        EN: "Governance"
    },
    account_staking: {
        EN: "Staking"
    },
    account_stakes: {
        EN: "Current Stakes"
    },
    account_rewards: {
        EN: "Rewards"
    },
    account_claim_rewards: {
        EN: "Claim Rewards"
    },
    account_restake: {
        EN: "Restake"
    },
    account_withdraw: {
        EN: "Withdraw"
    },
    account_unstakes: {
        EN: "Unstakes"
    },
    account_unstake: {
        EN: "Unstake"
    },
    account_add_stake: {
        EN: "Add Stake"
    },
    account_promotions: {
        EN: "Promotions"
    },
    account_vouchers: {
        EN: "Vouchers"
    },
    account_gift_vouchers: {
        EN: "Gift Vouchers"
    },
    account_premium: {
        EN: "Premium"
    },
    account_ads: {
        EN: "Ads"
    },
    account_ads_active: {
        EN: "Active Ads"
    },
    account_ads_expired: {
        EN: "Expired Ads"
    },
    save_changes: {
        EN: "Save Changes"
    },
    import_video: {
        EN: "Import Video"
    },
    change_password: {
        EN: "Change Password"
    },
    delete: {
        EN: "Delete"
    },
    edit: {
        EN: "Edit"
    },
    my_cart: {
        EN: "My Cart"
    },
    checkout: {
        EN: "Checkout"
    },
    order_confirmation: {
        EN: "Order Confirmation"
    },
    messages: {
        EN: "Messages"
    },
    create_listing: {
        EN: "Create Listing"
    },
    edit_listing: {
        EN: "Edit Listing"
    },
    not_found: {
        EN: "Not Found"
    },
    server_error: {
        EN: "Server Error"
    },
    shipping_zones: {
        EN: "Shipping Zones"
    },
    submit_listing: {
        EN: "Submit Listing"
    },
    end_listing: {
        EN: "End Listing"
    },
    send_refund: {
        EN: "Send Refund"
    },
    mark_dispatch: {
        EN: "Mark as Shipped"
    },
    unmark_dispatch: {
        EN: "Unmark Shipped"
    },
    claim_funds: {
        EN: "Claim Funds"
    },
    dispute: {
        EN: "Dispute"
    },
    create_dispute: {
        EN: "Create Dispute"
    },
    upload_evidence: {
        EN: "Upload Evidence"
    },
    view_evidence: {
        EN: "View Evidence"
    },
    vote_dispute: {
        EN: "Vote Dispute"
    },
    extend_escrow: {
        EN: "Extend Escrow"
    },
    mark_received: {
        EN: "Mark Completed"
    },
    place_order: {
        EN: "Place Order"
    },
    make_payment: {
        EN: "Make Payment"
    },
    select_address: {
        EN: "Select Address"
    },
    gen_address: {
        EN: "Generate Address"
    },
    send_tx: {
        EN: "Send Transaction"
    },
    migrate: {
        EN: "Migrate"
    },
    notifications: {
        EN: "Notifications"
    },
    become_seller: {
        EN: "Become A Seller"
    },
    create_account: {
        EN: "Create Account"
    },
    authenticate: {
        EN: "Authenticate"
    },
    buy_now: {
        EN: "Buy Now"
    },
    purchase_voucher: {
        EN: "Purchase Voucher"
    },
    redeem_voucher: {
        EN: "Redeem Voucher"
    },
    merge_stakes: {
        EN: "Merge Stakes"
    },
    vote: {
        EN: "Vote"
    },
    import_listing: {
        EN: "Import Listing"
    },
    leave_review: {
        EN: "Leave Review"
    },
    vote_bp: {
        EN: "Vote Block Producers"
    }
};
