/* global BigInt */

import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, dummyCategories, dummyWeeklyDeal, supportedCurrencies, supportedNetworks } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb";
import Sidebar from "components/account/sidebar";

var supportedCurrenciesCopy = [...supportedCurrencies];

function Wallet() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({  });
    const [data, setData] = useState({ balances: { STRX: 0, XPR: 0, BTC: 0, ETH: 0, DOGE: 0, METAL: 0, LOAN: 0, USDC: 0, USDT: 0, XMD: 0, SNIPS: 0, FOOBAR: 0 }, prices: { STRX: 0, XPR: 0, BTC: 0, ETH: 0, DOGE: 0, METAL: 0, LOAN: 0, USDC: 0, USDT: 0, XMD: 0, SNIPS: 0, FOOBAR: 0 }, depositAddresses: {  } });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        return () => {
            clearTimeout(window.balanceCheck);
        };
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/account/wallet"
            });
            
            return navigate("/");
        };

        if (state.authenticated) return handleDataFetch();
    }, [state.authenticated]);

    useEffect(() => {
        if (state.fetchWalletBalances) {
            setTimeout(() => {
                return handleDataFetch();
            }, 3000);
        };
    }, [state.fetchWalletBalances]);

    useEffect(() => {
        if (data.prices["STRX"] != 0) {
            handleFarmAPR();

            var convertParams = searchParams.get("convert");

            if (convertParams) {
                handleTrade(convertParams);
            };
        };
    }, [data.prices]);

    function handleFarmAPR() {
        // filter supportedCurrenciesCopy with configured farms
        var farmingCoins = supportedCurrenciesCopy.filter(currency => currency.farm);

        var farmAPRs = {};

        // iterate coins with configured farms
        farmingCoins.forEach(coin => {
            // fetch farm info
            Proton.fetchAlcorFarm(coin.farm).then(r => {
                var { poolId, reward, rewardRateE18 } = r;

                // fetch pool info
                Proton.fetchAlcorPool(poolId).then(r => {
                    var { tokenA, tokenB } = r;

                    // calculate annual rewards
                    var [ , rewardsTicker ] = reward.quantity?.split(" ");
                    var dailyRewards_bn = (BigInt(rewardRateE18) * BigInt(60 * 60 * 24)) / (BigInt(1000000000000000000) - BigInt(1));
                    var dailyRewards = Number(dailyRewards_bn) / 10000;
                    var annualRewards = dailyRewards * 365;

                    // calculate value of annual rewards
                    var annualRewardsValue = annualRewards * data.prices[rewardsTicker];

                    // calculate value of quote token in pool
                    var [ tokenAQuantity, tokenATicker ] = tokenA.quantity.split(" ");
                    var [ tokenBQuantity, tokenBTicker ] = tokenB.quantity.split(" ");
                    var [ quoteTokenQuantity, quoteTokenTicker ] = (rewardsTicker == tokenATicker) ? [tokenBQuantity, tokenBTicker] : [tokenAQuantity, tokenATicker];
                    var poolValue = (quoteTokenQuantity * data.prices[quoteTokenTicker] * 2);

                    // calculate APR
                    var farmAPR = annualRewardsValue / poolValue * 100;

                    farmAPRs[coin.farm] = farmAPR;

                    if (Object.keys(farmAPRs).length == farmingCoins.length) {
                        setData(values => (
                            { ...values, farmAPRs: farmAPRs }
                        ));
                    };
                }).catch(error => {
                    console.log(error);
                });  
            }).catch(error => {
                console.log(error);
            });         
        });
    };

    function handleDataFetch() {
        var balances = {};
        var prices = {};
        var oracles = {};

        Proton.fetchOracles().then(res => {
            var oracleArr = res?.rows || [];

            oracleArr.forEach((feed, index) => {
                var feedIndex = feed?.feed_index || 0;
                var feedPrice = feed?.aggregate?.d_double || 0;

                oracles[feedIndex] = feedPrice;

                if (index == oracleArr.length-1) {
                    supportedCurrenciesCopy.forEach((currency, index) => {
                        Proton.fetchBalance(state.session.auth.actor, currency).then(res => {
                            const [balanceStr] = res;
                            const [balance, ticker] = (balanceStr || "").split(" ");
            
                            balances[currency.ticker] = balance || "0";

                            prices[currency.ticker] = oracles[currency.feedIndex];

                            if (index == supportedCurrenciesCopy.length-1) {
                                Proton.fetchStorexOracles().then(res => {
                                    var oracleArr = res?.rows || [];
                        
                                    oracleArr.forEach((feed, index) => {
                                        const {sym, quantity} = feed;
                                        const [, ticker] = sym.split(",");
                                        const [amount] = quantity.split(" ");
                        
                                        prices[ticker] = amount;

                                        if (index == oracleArr.length-1) {
                                            setData(values => (
                                                { ...values, balances: balances, prices: prices }
                                            ));
                                        };
                                    });
                                }).catch(err => {
                                    console.log(err);
                        
                                    setTimeout(() => {
                                        handleDataFetch();
                                    }, 3000);
                                });
                            };
                        }).catch(err => {
                            console.log(err);
                
                            setTimeout(() => {
                                handleDataFetch();
                            }, 3000);
                        });
                    });
                };
            });
        }).catch(err => {
            console.log(err);

            setTimeout(() => {
                handleDataFetch();
            }, 3000);
        });

        Proton.fetchDepositAddresses(state.session).then(res => {
            if (!res.rows) return;

            var addressObj = {};

            res.rows.forEach(row => {
                addressObj[row.chain + "-WA"] = row.address;
            });

            setData(values => (
                { ...values, depositAddresses: { ...addressObj, ...values.depositAddresses } }
            ));
        }).catch(err => {
            console.log(err);

            setTimeout(() => {
                handleDataFetch();
            }, 3000);
        });

        Proton.fetchFireblocksDepositAddresses(state.session).then(res => {
            if (!res.rows) return;

            var addressObj = {};

            res.rows.forEach(row => {
                addressObj[row.chain + "-FB"] = row.address;
            });

            setData(values => (
                { ...values, depositAddresses: { ...values.depositAddresses, ...addressObj } }
            ));
        }).catch(err => {
            console.log(err);

            setTimeout(() => {
                handleDataFetch();
            }, 3000);
        });

        window.balanceCheck = setTimeout(() => {
            handleDataFetch();
        }, 30000);
    };

    function handleDeposit(currency, addresses) {
        $("div.input-modal").addClass("active").find("section.deposit-address").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "deposit",
                data: {
                    currency: currency,
                    addresses: addresses
                }
            }
        });
    };

    function handleWithdraw(currency, addresses) {
        $("div.input-modal").addClass("active").find("section.withdraw-address").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "withdraw",
                data: {
                    currency: currency,
                    addresses: addresses,
                    balance: data.balances[currency.ticker]
                }
            }
        });
    };

    function handleTrade(currency) {
        if (typeof currency !== "object") {
            console.log("c1", currency);

            currency = supportedCurrencies.filter(c => c.ticker == currency.toUpperCase())?.[0];

            console.log("c2", currency);
        };

        $("div.input-modal").addClass("active").find("section.trade").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "trade",
                data: {
                    currency: currency,
                    balances: data.balances,
                    prices: data.prices
                }
            }
        });
    };

    function handleFarm(currency) {
        $("div.input-modal").addClass("active").find("section.farm").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "farm",
                data: {
                    currency: currency,
                    balances: data.balances,
                    prices: data.prices
                }
            }
        });
    };

    function handleBPVote(currency) {
        $("div.input-modal").addClass("active").find("section.vote-bp").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "vote-bp",
                data: {
                    currency: currency,
                    balances: data.balances
                }
            }
        });
    };

    function handleFaucet(event, programId) {
        event.preventDefault();

        var showSuccess = (txId) => {
            $("div.error-modal").addClass("success").find("div.text").html("Your transaction has been successfully sent</br></br><a href='https://explorer.xprnetwork.org/transaction/" + txId + "' target='_blank'>View Transaction</a>");

            dispatch({
                type: "fetchWalletBalances",
                value: true
            });
        };

        var showError = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error sending your request, please contact support");
        };

        Proton.claimFaucet(state, programId).then(tx => {
            showSuccess(tx.processed.id);
        }).catch(e => {
            showError(e.error?.details?.[0]?.message || e);
        });
    };

    function toggleAssetCollapse(e, i) {
        if (e.target.className == "name") return;

        setInputs(values => (
            { ...values, [inputs.lastAssetToggle]: false, lastAssetToggle: "asset" + i, ["asset" + i]: !inputs?.["asset" + i] }
        ));
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["my_account"][state.language], path: "/account" },
        { label: textStrings["account_wallet"][state.language], path: "/account/wallet" }
    ]

    return (
        <section className="account">
            <div className="page-header">My Account</div>

            <div className="container">
                <div className="breadcrumb-wrapper border-bottom">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="account-wrapper">
                    <Sidebar />

                    <div className="content-wrapper">
                        <section className="wallet">
                            <div className="content-title-area">
                                <div className="content-title"><i className="fal fa-wallet"></i> { textStrings["account_wallet"][state.language] } <span><a href={ "https://explorer.xprnetwork.org/account/" + state.session.auth.actor } target="_blank">View block explorer</a></span></div>
                            </div>

                            <table>
                                <thead>
                                    <tr className="table-header">
                                        <td>Asset</td>
                                        <td>Balance</td>
                                        <td className="n480">Value</td>
                                        <i className="fal fa-chevron-up"></i>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        supportedCurrenciesCopy.sort((a, b) => (data.prices[b.ticker] * data.balances[b.ticker]) - (data.prices[a.ticker] * data.balances[a.ticker])).map((currency, index) => (
                                            <>
                                                <tr className="click" key={ index } onClick={ (e) => toggleAssetCollapse(e, index) }>
                                                    <td className="asset">
                                                        <img src={currency.logo} alt={currency.name + " token"} />
                                                        <a href={ "https://explorer.xprnetwork.org/tokens/" + currency.ticker + "-proton-" + currency.contract } target="_blank"><div className="name">{ currency.name }</div></a>
                                                        {
                                                            (currency.farm && data.farmAPRs?.[currency.farm]) && (
                                                                <span className="farm">{ data.farmAPRs?.[currency.farm]?.toFixed(0) }% APR</span>
                                                            )
                                                        }
                                                        {
                                                            (currency.bp) && (
                                                                <span className="farm">Vote BP</span>
                                                            )
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            (data.balances[currency.ticker]) ? parseFloat(data.balances[currency.ticker]).toLocaleString("en-US", { minimumFractionDigits: currency.precision }) : <i className="fad fa-spinner-third"/>
                                                        }
                                                    </td>
                                                    <td className="n480">
                                                        {
                                                            (data.prices[currency.ticker] && data.balances[currency.ticker]) ? parseFloat(data.prices[currency.ticker] * data.balances[currency.ticker]).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) : <i className="fad fa-spinner-third"/>
                                                        }
                                                    </td>
                                                    <i className={ (inputs?.["asset" + index]) ? "fal fa-chevron-up" : "fal fa-chevron-down" }></i>
                                                </tr>
                                                <tr className={ (inputs?.["asset" + index]) ? "expand" : "collapse" }>
                                                    <div className="expand-gr">
                                                        {
                                                            (currency.bp) && (
                                                                <a onClick={ () => handleBPVote(currency) }><button className="green">Vote BP</button></a>
                                                            )
                                                        }
                                                        {
                                                            (currency.farm) && (
                                                                <a onClick={ () => handleFarm(currency) }><button className="green">Yield Farm</button></a>
                                                            )
                                                        }
                                                        {
                                                            (currency.chains) && (
                                                                <a onClick={ () => handleDeposit(currency, data.depositAddresses) }><button>Receive</button></a>
                                                            )
                                                        }
                                                        {
                                                            (currency.chains) && (
                                                                <a onClick={ () => handleWithdraw(currency) }><button>Send</button></a>
                                                            )
                                                        }
                                                        {
                                                            (currency.trade) && (
                                                                <a onClick={ () => handleTrade(currency) }><button>Convert</button></a>
                                                            )
                                                        }
                                                        {
                                                            (currency.faucet != null) && (
                                                                <a onClick={ (e) => handleFaucet(e, currency.faucet) }><button>Claim { currency.ticker }</button></a>
                                                            )
                                                        }
                                                        {
                                                            (currency.voucher) && (
                                                                <Link to="/account/vouchers"><button>Buy</button></Link>
                                                            )
                                                        }
                                                    </div>
                                                </tr>
                                            </>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </section>

                        <section className="nfts">
                            <div className="content-title-area">
                                <div className="content-title divider"><i className="fal fa-file-image"></i> { textStrings["account_nfts"][state.language] }</div>
                            </div>

                            <table>
                                <thead>
                                    <tr className="table-header">
                                        <td>Asset</td>
                                        <td>Quantity</td>
                                        <td>Available</td>
                                        <td>In Use</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="empty">
                                        <td>No supported NFTs</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Wallet;
